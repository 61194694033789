import "./styles.scss"
import "react-image-lightbox/style.css"

import React, { useState } from "react"
import Lightbox from "react-image-lightbox"

import PageHeader from "components/PageHeader"

const Gallery = () => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const gallery = [
    require("assets/images/investment-gallery-image-1.jpg").default,
    require("assets/images/investment-gallery-image-2.jpg").default,
    require("assets/images/investment-gallery-image-3.jpg").default,
    require("assets/images/investment-gallery-image-4.jpg").default,
    require("assets/images/investment-gallery-image-5.jpg").default,
    require("assets/images/investment-gallery-image-6.jpg").default,
    require("assets/images/investment-gallery-image-7.jpg").default,
    require("assets/images/investment-gallery-image-8.jpg").default,
    require("assets/images/investment-gallery-image-9.jpg").default,
    require("assets/images/investment-gallery-image-10.jpg").default,
    require("assets/images/investment-gallery-image-11.jpg").default,
    require("assets/images/investment-gallery-image-12.jpg").default,
    require("assets/images/investment-gallery-image-13.jpg").default,
    require("assets/images/investment-gallery-image-14.jpg").default,
    require("assets/images/investment-gallery-image-15.jpg").default,
    require("assets/images/investment-gallery-image-16.jpg").default,
  ]

  return (
    <section className="home-gallery">
      <div className="container-fluid">
        <PageHeader title="Galeria" type="sub" color="gray" />

        <div className="row">
          <div className="col-md-8">
            <a
              href="/"
              className="home-gallery__item"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-1.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(0)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-4">
            <a
              href="/"
              className="home-gallery__item"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-2.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(1)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-4">
            <a
              href="/"
              className="home-gallery__item"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-3.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(2)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-8">
            <a
              href="/"
              className="home-gallery__item"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-4.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(3)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-3">
            <a
              href="/"
              className="home-gallery__item home-gallery__item--lower"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-5.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(4)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-3">
            <a
              href="/"
              className="home-gallery__item home-gallery__item--lower"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-6.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(5)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-6">
            <a
              href="/"
              className="home-gallery__item home-gallery__item--lower"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-7.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(6)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-6">
            <a
              href="/"
              className="home-gallery__item home-gallery__item--lower"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-8.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(7)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-6">
            <a
              href="/"
              className="home-gallery__item home-gallery__item--lower"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-9.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(8)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-4">
            <a
              href="/"
              className="home-gallery__item"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-10.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(9)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-8">
            <a
              href="/"
              className="home-gallery__item"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-11.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(10)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-8">
            <a
              href="/"
              className="home-gallery__item"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-12.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(11)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-4">
            <a
              href="/"
              className="home-gallery__item"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-13.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(12)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-6">
            <a
              href="/"
              className="home-gallery__item home-gallery__item--lower"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-14.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(13)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-3">
            <a
              href="/"
              className="home-gallery__item home-gallery__item--lower"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-15.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(14)
              }}
            >
              Gallery item
            </a>
          </div>

          <div className="col-md-3">
            <a
              href="/"
              className="home-gallery__item home-gallery__item--lower"
              style={{
                backgroundImage: `url('${
                  require("assets/images/investment-gallery-image-16.jpg")
                    .default
                }')`,
              }}
              onClick={e => {
                e.preventDefault()
                setIsLightboxOpen(true)
                setPhotoIndex(15)
              }}
            >
              Gallery item
            </a>
          </div>
        </div>
      </div>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex]}
          nextSrc={gallery[(photoIndex + 1) % gallery.length]}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </section>
  )
}

export default Gallery
