import "./styles.scss"

import React, { useRef } from "react"
import Slider from "react-slick"

const Grid = () => {
  const sliderRef = useRef(null)

  const slider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  return (
    <section className="home-grid">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-6 offset-xxl-6">
            <div className="home-grid__row">
              <div className="home-grid__item">
                <div className="home-grid__item-image">
                  <img
                    src={
                      require("assets/icons/features/time.svg").default
                    }
                    alt=""
                  />
                </div>
                <h4>Dla osób ceniących swój czas</h4>
              </div>
              <div className="home-grid__item">
                <div className="home-grid__item-image">
                  <img
                    src={
                      require("assets/icons/features/activity.svg").default
                    }
                    alt=""
                  />
                </div>
                <h4>Dla osób żyjących aktywnie</h4>
              </div>
              <div className="home-grid__item">
                <div className="home-grid__item-image">
                  <img
                    src={
                      require("assets/icons/features/comfort.svg").default
                    }
                    alt=""
                  />
                </div>
                <h4>Dla osób ceniących najwyższy komfort</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="home-grid__divider--100" />

        <div className="row">
          <div className="col-xl-4 col-md-5">
            <h2>Tereny zielone</h2>
            <div className="home-grid__inner">
              Inwestycja jest umiejscowiona w pobliżu malowniczych terenów
              zielonych, w bezpośrednim sąsiedztwie znajduje się Młynówka
              Królewska. Możliwość korzystania z przylegających terenów
              rekreacyjnych i spacerów po pięknych parkach, przyciąga do siebie
              tych, którzy pragną życia w zgodzie z naturą, nie rezygnując
              jednak z wygody miejskiego życia.
            </div>
          </div>
          <div className="col-xl-7 offset-xl-1 col-md-7">
            <div className="home-grid__image home-grid__image--1">
              <img
                src={
                  require("assets/images/investment-grid-image-1.jpg").default
                }
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="home-grid__divider--min-50" />

        <div className="row align-items-end flex-column-reverse flex-md-row">
          <div className="col-xl-5 col-md-5">
            <div className="home-grid__image home-grid__image--2">
              <img
                src={
                  require("assets/images/investment-grid-image-2.jpg").default
                }
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-6 col-md-7">
            <h2>Nowoczesny styl architektoniczny</h2>
            <div className="home-grid__inner">
              Prostota i klasyczna elegancja to niewątpliwe atuty Mazowieckiej
              115. Duża ilość przeszkleń, ceglane elementy, proste linie i
              wykończenia tworzą spójną, nienachalną koncepcję wizualną, która
              zgrabnie wpisuje się w otoczenie. Mazowiecka 115 to nowoczesna
              koncepcja architektoniczna, która dzięki czterem odrębnym
              segmentom fasady przywodzi na myśl pierzeje dostojnych kamienic
              Starego Miasta.
            </div>
          </div>
        </div>

        <div className="home-grid__divider--200" />

        <div className="row">
          <div className="col-md-6">
            <h2>Wszystko co potrzebne krok od domu</h2>
            <div className="row">
              <div className="col-xl-10">
                <div className="home-grid__inner">
                  W naszej inwestycji nie tylko zapewniamy doskonałe warunki
                  mieszkaniowe, ale także dbamy o pełen komfort i wygodę
                  codziennego życia poprzez bogaty wybór usług dostępnych na
                  miejscu. Rzemieślnicza piekarnia, sklep spożywczy, salon
                  beauty czy restauracja wszystko to znajdziesz tuż obok
                  własnego mieszkania, oszczędzając cenny czas na dojazdy.
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="home-grid__image home-grid__image--3">
              <Slider ref={sliderRef} {...slider}>
                <div>
                  <img
                    src={
                      require("assets/images/investment-grid-image-5.jpg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src={
                      require("assets/images/investment-grid-image-6.jpg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <div>
                  <img
                    src={
                      require("assets/images/investment-grid-image-7.jpg")
                        .default
                    }
                    alt=""
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="home-grid__divider--50" />

        <div className="row align-items-center flex-column-reverse flex-md-row">
          <div className="col-xl-5 col-md-6">
            <div className="home-grid__image home-grid__image--4">
              <img
                src={
                  require("assets/images/investment-grid-image-4.jpg").default
                }
                alt=""
              />
            </div>
          </div>
          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h2>
              Mieszkania z dużą ilością <br />
              światła dziennego
            </h2>
            <div className="home-grid__inner">
              Duże okna, niska zabudowa okolicy, brak możliwości jej dalszej
              rozbudowy oraz znaczne odległości do sąsiadujących budynków
              gwarantują dostęp do dużej ilości naturalnego światła.
            </div>
          </div>
        </div>

        <div className="home-grid__divider--min-50" />

        <div className="row align-items-end">
          <div className="col-md-6">
            <h2>
              Wspólny ogród o powierzchni 500&nbsp;m<sup>2</sup>
            </h2>
            <div className="row">
              <div className="col-xl-10">
                <div className="home-grid__inner">
                  Przestronny, pięknie zaaranżowany ogród to przede wszystkim
                  strefa wytchnienia od miejskiego zgiełku. Nastrojowe
                  oświetlenie, wielobarwna, zróżnicowana roślinność oraz strefa
                  relaksu z ławkami i hamakami, tworzą idealną, kameralną
                  przestrzeń do spotkań i wypoczynku na świeżym powietrzu.
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="home-grid__image home-grid__image--3">
              <img
                src={
                  require("assets/images/investment-grid-image-3.jpg").default
                }
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Grid
