import "./styles.scss"

import React from "react"

const Convenience = () => {
  return (
    <section className="home-convenience">
      <div className="container-fluid">
        <div className="home-convenience__wrapper">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="home-convenience__item">
                <div className="home-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-1.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h4>Wygodna lokalizacja</h4>
                <p>
                  Ul. Mazowiecka w dzielnicy Krowodrza, to tętniące życiem serce
                  Krakowa, z łatwym dojazdem i wygodnym dostępem pieszym do
                  Starego Miasta i alei Trzech Wieszczów. Popularne miejsca
                  rozrywki, kultury, biznesu i spotkań znajdują się tuż o krok.
                  Krowodrza to zielona dzielnica bogata w parki i zielone
                  skwerki. W bezpośrednim sąsiedztwie znajduje się Młynówka
                  Królewska z Aleją Grottgera, w bliskiej odległości również
                  parki: Kleparski, Krakowski i Jordana.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="home-convenience__item">
                <div className="home-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-2.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h4>Nowoczesna architektura</h4>
                <p>
                  Nowoczesny styl to propozycja dla osób, które cenią prostotę i
                  klasyczną elegancję. Proste fasady łączą dużą ilość szklanych
                  i ceglanych elementów. 4 różne segmenty budynku kojarzą się z
                  pierzeją kamienic Starego Miasta tworząc niepowtarzalną,
                  gościnną atmosferę.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="home-convenience__item">
                <div className="home-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-3.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h4>Zabytkowy akcent</h4>
                <p>
                  Nowoczesna zabudowa łączy się w tym miejscu z zabytkowym
                  fragmentem Krakowa. Część budynku to odnowiona, pozostająca
                  pod nadzorem konserwatora zabytków, zabudowa z końca XIX
                  wieku, w której niegdyś znajdowała się karczma.
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="home-convenience__item">
                <div className="home-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-4.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h4>Balkony, tarasy i ogrody</h4>
                <p>
                  Każde z mieszkań, w zależności od metrażu i umiejscowienia,
                  posiada odrębny balkon, taras lub ogród.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="home-convenience__item">
                <div className="home-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-5.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h4>Prywatny wspólny ogród</h4>
                <p>
                  Dla dyspozycji mieszkańców powstał duży, funkcjonalnie
                  zaaranżowany wspólny ogród o powierzchni 500 m². To
                  wytchnienie od miejskiego zgiełku. Nastrojowe oświetlenie,
                  wielobarwne kwiaty i trawy tworzą przytulny, sprzyjający
                  odpoczynkowi nastrój. Na miejscu między innymi hamaki, ławki i
                  stoliki szachowe w otoczeniu dużej ilości zieleni.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="home-convenience__item">
                <div className="home-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-6.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h4>Słoneczne przestrzenie</h4>
                <p>
                  Mazowiecka 115 to ogrom naturalnego światła. Duże okna, niska
                  zabudowa oraz ekspozycja na różne strony świata zapewnią
                  doskonałe doświetlenie mieszkań.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="home-convenience__item">
                <div className="home-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-8.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h4>Mieszkania z możliwością łączenia</h4>
                <p>
                  Większość mieszkań oferuje możliwość łączenia w poziomie lub w
                  pionie. Idealne rozwiązanie dla osób, które potrzebują
                  większego metrażu.
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-sm-6">
              <div className="home-convenience__item">
                <div className="home-convenience__item-image">
                  <img
                    src={
                      require("assets/images/home-convenience-ico-7.svg")
                        .default
                    }
                    alt=""
                  />
                </div>
                <h4>Usługi i sklepy na parterze</h4>
                <p>
                  Na parterze budynku znajdują się lokale usługowe i sklepy,
                  odpowiadające na najpilniejsze potrzeby mieszkańców.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Convenience
